import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Navigation from './Navigation';
import { useNavigate } from 'react-router-dom';
import { signOut,onAuthStateChanged  } from 'firebase/auth'; // Import Firebase auth functions
import { auth } from "../firebase/firebase";
import supabase from '../supabase/supabase'; // Import your Supabase client instance
import app from '../firebase/firebase'; // Import your Firebase app instance

function History() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');

  useEffect(() => {
    // Use onAuthStateChanged to track authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        fetchUserName(user.email);
      } else {
        // User is signed out
        setUserName('');
      }
    });

    return () => {
      // Clean up the listener
      unsubscribe();
    };
   
    
    // Query the users table to get the user's name based on their email
    async function fetchUserName() {
      try {
        const user = auth.currentUser; // Get the currently logged-in user
        if (user) {
          const { data, error } = await supabase
            .from('users')
            .select('name')
            .eq('email', user.email)
            .single();

          if (data) {
            setUserName(data.name);
          } else if (error) {
            console.error('Error fetching user name:', error.message);
          }
        }
      } catch (error) {
        console.error('Error fetching user name:', error.message);
      }
    }

    fetchUserName();
  }, []);

  
  const handleSignOut = async () => {
    try {
      await signOut(auth); // Use signOut function
      navigate('/'); // Navigate to the login page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Layout>
      <div className="login-container">
        <main className="content">
          <div className="centered-form">
            <h2>Welcome, {userName}!</h2>
            <button onClick={handleSignOut}>Sign Out</button>
          </div>
          <Navigation></Navigation>
        </main>
      </div>
    </Layout>
  );
}

export default History;
