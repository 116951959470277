import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Navigation from './Navigation';
import { useNavigate } from 'react-router-dom';
import { signOut,onAuthStateChanged  } from 'firebase/auth'; // Import Firebase auth functions
import { auth } from "../firebase/firebase";
import supabase from '../supabase/supabase'; // Import your Supabase client instance
import "../style/Exercises.css";

function Exercises() {
  const navigate = useNavigate();
  const [userID, setUserID] = useState('');
  const [userName, setUserName] = useState('');
  const [exercises, setExercises] = useState([]);
  

  useEffect(() => {
    
    // Use onAuthStateChanged to track authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        fetchUserName(user.email);
      } else {
        // User is signed out
        setUserName('');
      }
    });
    return () => {
      // Clean up the listener
      unsubscribe();
    };
    
    
  }, []);

// Added new useEffect to fetch exercises when userID changes
useEffect(() => {
  if (userID) {
    fetchExercises();
  }
}, [userID]);

    // Query the users table to get the user's name based on their email
    async function fetchUserName() {
      try {
        const user = auth.currentUser; // Get the currently logged-in user
        if (user) {
          const { data, error } = await supabase
            .from('users')
            .select('id,name')
            .eq('email', user.email)
            .single();

          if (data) {

            //console.log('Email:' + user.email);
            //console.log('UserID:' + data.id);
            //console.log('User:' + data.name);
            
            setUserID(data.id);
            setUserName(data.name);
          } else if (error) {
            console.error('Error fetching user name:', error.message);
          }
        }
      } catch (error) {
        console.error('Error fetching user name:', error.message);
      }
    }

    // Fetch exercises data from the Supabase table
    async function fetchExercises() {
      try {
        const { data, error } = await supabase
          .from('exercises')
          .select('id,name,bodypartID,exercises_bodyparts(name),exercises_category(name),userID')
          .or(`userID.eq.${userID},userID.is.null`); // Changed this line

        if (data) {
          setExercises(data);
        } else if (error) {
          console.error('Error fetching exercises:', error.message);
        }
      } catch (error) {
        console.error('Error fetching exercises:', error.message);
      }
    }

    const handleSignOut = async () => {
    try {
      await signOut(auth); // Use signOut function
      navigate('/'); // Navigate to the login page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const AddExercise = async () => {
      navigate('/addexercise'); // Navigate to the login page
  }
  
  const handleDeleteExercise = async (exerciseId) => {
    try {
      // Delete the exercise from the database
      const { error } = await supabase
        .from('exercises')
        .delete()
        .eq('id', exerciseId);

      if (!error) {
        // Remove the exercise from the state
        setExercises((prevExercises) => prevExercises.filter((exercise) => exercise.id !== exerciseId));
      } else {
        console.error('Error deleting exercise:', error.message);
      }
    } catch (error) {
      console.error('Error deleting exercise:', error.message);
    }
  };

  return (
    <Layout>
      <div className="login-container">
        <main className="content">
          <div className="centered-form">
            <h2>Exercises | <button onClick={AddExercise}>Add</button></h2>

            <table className="exercise-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Bodypart</th>
                  <th>Category</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {exercises.map((exercise) => (
                  <tr key={exercise.id}>
                  <td>{exercise.name}</td>
                  <td>{exercise.exercises_bodyparts?.name ?? 'N/A'}</td>
                  <td>{exercise.exercises_category?.name ?? 'N/A'}</td>
                  <td>
                  {exercise.userID !== 0 && exercise.userID !== null && (
                      <button className="delete-button" onClick={() => handleDeleteExercise(exercise.id)}>X</button>
                  )}
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Navigation></Navigation>
        </main>
      </div>
    </Layout>
  );
}

export default Exercises;
