import React from 'react';

function Layout({ children }) {
  return (
    <div className="layout">
      <div className="top-bar">
        <div className="top-bar-content">
          <header className="header">
            <div className="logo"><img src="logo.png" alt="Lightweight Training" className="logoimage" /></div>
          </header>
        </div>
      </div>
      <div className="page-content">{children}</div>
      <div className="bottom-bar">
        <div className="bottom-bar-content">&copy;2023 LightWeight.app by MooreLifting&nbsp;&nbsp;</div>
      </div>
    </div>
  );
}

export default Layout;
