import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navigation() {
  const location = useLocation();

  return (
    <div className="navigation-frame">
      <Link to="/home" className={location.pathname === '/home' ? 'active' : ''}>Home</Link>
      <Link to="/history" className={location.pathname === '/history' ? 'active' : ''}>History</Link>
      <Link to="/startworkout" className={location.pathname === '/startworkout' ? 'active' : ''}>Start Workout</Link>
      <Link to="/exercises" className={location.pathname === '/exercises' ? 'active' : ''}>Exercises</Link>
    </div>
  );
}

export default Navigation;