import "./style/App.css";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Register from './components/Register';
import Reset from './components/Reset';
import Exercises from './components/Exercises';
import History from './components/History';
import StartWorkout from './components/StartWorkout';
import AddExercise from './components/AddExercise';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Reset" element={<Reset />} />
            <Route path="/Exercises" element={<Exercises/>} />
            <Route path="/History" element={<History/>} />
            <Route path="/StartWorkout" element={<StartWorkout/>} />
            <Route path="/AddExercise" element={<AddExercise/>} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
