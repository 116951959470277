import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Navigation from './Navigation';
import { useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth'; // Import Firebase auth functions
import { auth } from "../firebase/firebase";
import supabase from '../supabase/supabase'; // Import your Supabase client instance
import sanitizeInput from './Utility'; // Update the path accordingly

function AddExercise() {
  const navigate = useNavigate();
  const [userID, setUserID] = useState('');
  const [userName, setUserName] = useState('');
  const [name, setName] = useState('');
  const [bodypartID, setBodypartID] = useState('');
  const [categoryID, setCategoryID] = useState('');
  const [bodypartOptions, setBodypartOptions] = useState([]); // Store bodypart options
  const [categoryOptions, setCategoryOptions] = useState([]); // Store category options

  useEffect(() => {
    // Use onAuthStateChanged to track authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        fetchUserName(user.email);
        fetchBodypartOptions();
        fetchCategoryOptions();
      } else {
        // User is signed out
        setUserName('');
      }
    });

    return () => {
      // Clean up the listener
      unsubscribe();
    };

    // Query the users table to get the user's name based on their email
    async function fetchUserName() {
      try {
        const user = auth.currentUser; // Get the currently logged-in user
        if (user) {
          const { data, error } = await supabase
            .from('users')
            .select('id,name')
            .eq('email', user.email)
            .single();

          if (data) {

            console.log('UserID:' + data.id)
            console.log('User:' + data.name)

            setUserID(data.id);
            setUserName(data.name);
          } else if (error) {
            console.error('Error fetching user name:', error.message);
          }
        }
      } catch (error) {
        console.error('Error fetching user name:', error.message);
      }
    }

    // Fetch bodypart options
    async function fetchBodypartOptions() {
      try {
        const { data, error } = await supabase
          .from('exercises_bodyparts')
          .select('id, name')
          .order('id');

        if (data) {
          setBodypartOptions(data);
        } else if (error) {
          console.error('Error fetching bodypart options:', error.message);
        }
      } catch (error) {
        console.error('Error fetching bodypart options:', error.message);
      }
    }

    // Fetch category options
    async function fetchCategoryOptions() {
      try {
        const { data, error } = await supabase
          .from('exercises_category')
          .select('id, name')
          .order('id');

        if (data) {
          setCategoryOptions(data);
        } else if (error) {
          console.error('Error fetching category options:', error.message);
        }
      } catch (error) {
        console.error('Error fetching category options:', error.message);
      }
    }
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth); // Use signOut function
      navigate('/'); // Navigate to the login page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform data validation if needed
  
    const userIDToInsert = userID ? parseInt(userID, 10) : null;
    const bodypartIDToInsert = bodypartID !== "" ? parseInt(bodypartID, 10) : null;
    const categoryIDToInsert = categoryID !== "" ? parseInt(categoryID, 10) : null;
  
    const { data, error } = await supabase
      .from('exercises')
      .insert([
        { userID: userIDToInsert, name, bodypartID: bodypartIDToInsert, categoryID: categoryIDToInsert }
      ]);
  
    console.log('adding exercise', userIDToInsert, name, bodypartIDToInsert, categoryIDToInsert);
    navigate('/exercises');
  };

  const Cancel = async () => {
    navigate('/exercises'); // Navigate to the login page
  }

  return (
    <Layout>
      <div className="login-container">
        <main className="content">
          <div className="centered-form">
            <h2>Add New Exercise</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(sanitizeInput(e.target.value))}
                />
              </div>
              <div className="form-group">  
                <label htmlFor="bodypartID">Bodypart:</label>
                <select
                  id="bodypartID"
                  value={bodypartID}
                  onChange={(e) => setBodypartID(sanitizeInput(e.target.value))}
                >
                  {bodypartOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="categoryID">Category:</label>
                <select
                  id="categoryID"
                  value={categoryID}
                  onChange={(e) => setCategoryID(sanitizeInput(e.target.value))}
                >
                    {categoryOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              <input type="hidden" name="userID" value={userID} />
              

              <button type="submit">Add Exercise</button>
              <button onClick={Cancel}>Cancel</button>
            </form>
          </div>
          <Navigation></Navigation>
        </main>
      </div>
    </Layout>
  );
}

export default AddExercise;
